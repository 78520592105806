<template>
    <div>
        <TopMenu/>
        <v-container>
            Edit profile
        </v-container>
    </div>
</template>

<script>
    import baseViewMixin from "@/mixins/baseView";
    import currentUserMixin from "@/mixins/currentUser";

    import TopMenu from "@/components/WFSite/TopMenu";

    export default {
        mixins: [baseViewMixin, currentUserMixin],
        components: { TopMenu, },
    }
</script>

<style lang="scss" scoped>

</style>